
import { Vehicle } from "@/shared/models";
import CustomVehicleIcon from "@/components/CustomVehicleIcon.vue";
export default {
  name: "VehicleCard",
  props: {
    vehicle: Object as () => Vehicle | undefined
  },
  components: { CustomVehicleIcon }
};
