
import Vue from "vue";
export default Vue.extend({
  name: "CustomVehicleIcon",
  props: {
    color: {
      default: "#000000" as string
    },
    size: {
      default: 42 as number
    }
  }
});
