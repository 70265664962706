import { DateTime, Duration } from "luxon";

/** Enums */

export enum AlertTemplate {
  OrderPlacementTemplate = "OrderPlacementTemplate",
  OrderApprovedTemplate = "OrderApprovedTemplate",
  UserAddedTemplate = "UserAddedTemplate",
  VehicleAddedTemplate = "VehicleAddedTemplate",
  VehicleDeletedTemplate = "VehicleDeletedTemplate",
  GeofenceAlertTemplate = "GeofenceAlertTemplate",
  DonglePlugAlertTemplate = "DonglePlugAlertTemplate",
  VehicleTowAlertTemplate = "VehicleTowAlertTemplate",
  BookingAddedTemplate = "BookingAddedTemplate",
  BookingDeletedTemplate = "BookingDeletedTemplate",
  CrashDetectionTemplate = "CrashDetectionTemplate"
}

export enum JobStatus {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED"
}

export enum VehicleType {
  COMPANY = "Company",
  PRIVATE = "Private",
  POOL = "Pool"
}

export enum tripCategory {
  Private = "PRIVATE",
  Business = "BUSINESS",
  Unmarked = "UNMARKED"
}

export enum userRoles {
  Admin = "admin",
  User = "user"
}

export enum RouteHistoryMode {
  RAW = "RAW",
  TRIP = "TRIP"
}

export enum GeoFenceType {
  Radius = "radius",
  Polygon = "polygon"
}

export enum MileageIgnoreZoneType {
  Radius = "radius",
  Polygon = "polygon"
}

/** Interfaces */

export type behaveId = 10 | 11 | 12 | 13;

export interface Vehicle {
  vehicleType: string;
  nickname?: string | undefined;
  make: string;
  model: string;
  registrationNo: string;
  dongleActive: boolean;
  dongleId?: string;
  milTrackerActive: boolean;
  odomMileageActive: boolean;
  odomMileage: number;
  bookingActive: boolean;
  routeHistActive: boolean;
  archived: boolean;
  id: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface Tenant {
  customerNo: string;
  companyName?: string;
  billingAddress: string;
  email: string;
  phoneNo?: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface User {
  id: string;
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  tenant: Tenant;
  createdAt: Date;
  updatedAt: Date;
}

export interface Job {
  title: string;
  description?: string;
  postcode?: string;
  startDate?: string;
  endDate?: string;
  jobNo: string;
  status: JobStatus;
  assignee: string;
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Booking {
  comment?: string;
  startTime: Date;
  endTime: Date;
  assignee: User;
  vehicle: Vehicle;
  jobs: Job[];
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface VehicleExpense {
  expenseType: string;
  merchant?: string;
  price: number;
  dateTime?: string | Date;
  recurring: boolean;
  taxPercent?: number;
  location?: string;
  comment?: string;
  id: string;
  trip?: Trip;
  createdAt: Date;
  updatedAt: Date;
  attachmentFileKeys?: undefined | string[];
  attachments?: undefined | [{ fileKey: string; url: string }];
}

export interface Trip {
  sourceLocation?: string;
  destinationLocation?: string;
  sourceLatitude?: number;
  sourceLongitude?: number;
  destinationLatitude?: number;
  destinationLongitude?: number;
  startTime?: Date;
  endTime?: Date;
  tripDistance?: number;
  jobs: Job[];
  category?: tripCategory;
  driverId?: string;
  comment?: string;
  vehicle: Vehicle;
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface TripPage {
  currentPage: number;
  items: Trip[];
  itemsCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export interface TripCardComponentProps {
  startTime: undefined | DateTime;
  endTime: undefined | DateTime;
  tripDistance: undefined | number;
  category: undefined | tripCategory;
}

export interface GeoFence {
  name: string;
  type: string;
  description?: string;
  sourceLatitude: number;
  sourceLongitude: number;
  radius: number;
  alertLeavingArea?: boolean;
  alertEnteringArea?: boolean;
  vehicles: Vehicle[];
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
}
