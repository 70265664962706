var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon-outline"},[_c('div',{staticClass:"icon-container",class:{ 'icon-parked': false },style:('background-color:' +
        _vm.color +
        ';height:' +
        _vm.size +
        'px;width:' +
        _vm.size +
        'px;')},[_c('img',{attrs:{"src":require("@/assets/img/car-icon-transparent-white.svg"),"width":_vm.size + 'px'}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }