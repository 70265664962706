
import Vue from "vue";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
import VehicleCard from "@/components/VehicleCard.vue";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
import router from "@/router";
import reject from "lodash/reject";
import { Vehicle, VehicleType, userRoles } from "@/shared/models";
import sharedData from "@/shared/data";

export default Vue.extend({
  name: "ArchivedVehicle",
  components: { VehicleCard, NoTenantSelectedMessage },
  data() {
    return {
      userRoles,
      VehicleType,
      vehicles: undefined as undefined | Vehicle[],
      filteredVehicles: undefined as undefined | Vehicle[],
      addVehicleToggle: false,
      filters: {
        typeCompany: false,
        typePrivate: false,
        typePool: false,
        searchQuery: undefined as undefined | string,
        category: [] as string[]
      },
      addVehicleDetail: {
        vehicleType: undefined,
        nickname: undefined,
        make: undefined,
        model: undefined,
        registrationNo: undefined,
        dongleActive: false,
        dongleId: undefined,
        milTrackerActive: false,
        odomMileageActive: false,
        odomMileage: undefined,
        bookingActive: false,
        routeHistActive: false
      },
      vehicleList: sharedData.vehicleModels
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
    }
  },
  created() {
    this.initializeView();
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        this.getVehicles();
        // setting filters if query has any filters selected
        if (this.$route.query.category) {
          this.filters.category = this.$route.query.category
            .toString()
            .split(",");
        }
      }
    },
    openAddVehicleModal() {
      this.addVehicleToggle = true;
    },
    clearVehicleTypeFilter() {
      this.filters.category = [];
      this.filterVehicles();
    },
    filterVehicles() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          category: this.filters.category.toString()
        }
      });
      // load vehicles to filter
      this.filteredVehicles = this.vehicles;
      // check if any filter applied, skip if none applied
      this.filters.typeCompany = false;
      this.filters.typePrivate = false;
      this.filters.typePool = false;
      for (let i = 0; i < this.filters.category.length; i++) {
        if (this.filters.category[i] === VehicleType.COMPANY) {
          this.filters.typeCompany = true;
        }
        if (this.filters.category[i] === VehicleType.PRIVATE) {
          this.filters.typePrivate = true;
        }
        if (this.filters.category[i] === VehicleType.POOL) {
          this.filters.typePool = true;
        }
      }
      // check if any filter applied, skip if none applied
      if (
        this.filters.typePrivate ||
        this.filters.typeCompany ||
        this.filters.typePool
      ) {
        if (!this.filters.typeCompany) {
          this.filteredVehicles = reject(this.filteredVehicles, {
            vehicleType: VehicleType.COMPANY
          }) as Vehicle[];
        }
        if (!this.filters.typePrivate) {
          this.filteredVehicles = reject(this.filteredVehicles, {
            vehicleType: VehicleType.PRIVATE
          }) as Vehicle[];
        }
        if (!this.filters.typePool) {
          this.filteredVehicles = reject(this.filteredVehicles, {
            vehicleType: VehicleType.POOL
          }) as Vehicle[];
        }
      }
      // check if search query
      if (this.filters.searchQuery) {
        this.filteredVehicles = this.filteredVehicles?.filter((vehicle) => {
          // todo -
          return vehicle.nickname
            ?.toLowerCase()
            .includes(this.filters.searchQuery!.toLowerCase());
        });
      }
    },
    addVehicle() {
      const request = {
        vehicleType: this.$data.addVehicleDetail.vehicleType,
        nickname: this.$data.addVehicleDetail.nickname,
        make: this.$data.addVehicleDetail.make,
        model: this.$data.addVehicleDetail.model,
        registrationNo: this.$data.addVehicleDetail.registrationNo,
        dongleActive: this.$data.addVehicleDetail.dongleActive,
        dongleId: this.$data.addVehicleDetail.dongleId,
        milTrackerActive: this.$data.addVehicleDetail.milTrackerActive,
        odomMileageActive: this.$data.addVehicleDetail.odomMileageActive,
        odomMileage: this.$data.addVehicleDetail.odomMileage,
        bookingActive: this.$data.addVehicleDetail.bookingActive,
        routeHistActive: this.$data.addVehicleDetail.routeHistActive
      };
      api
        .post("/tenants/" + this.selectedTenant + "/vehicles", request)
        .then((res) => {
          notification.success("Vehicle added");
          this.addVehicleToggle = false;
          this.getVehicles();
        })
        .catch((err) => {
          notification.error(err.message);
        });
    },
    viewVehicleDetail(vehicleId: string) {
      router.push({
        name: "VehicleDetail",
        query: { vehicleId: vehicleId }
      });
    },
    getVehicles() {
      api
        .get("/tenants/" + this.selectedTenant + "/vehicles", {
          params: { archived: true }
        })
        .then((res) => {
          this.vehicles = res.data;
          this.filterVehicles();
        })
        .catch((err) => {
          notification.error(err.message);
        });
    },
    archiveVehicle(id: string, archived: boolean) {
      api
        .put("/vehicles/" + id, { archived: archived })
        .then((res) => {
          if (archived) notification.success("Vehicle archived");
          else notification.success("Vehicle unarchived");
          this.getVehicles();
        })
        .catch((err) => {
          notification.error(err.message);
        });
    }
  }
});
